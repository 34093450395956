import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import acl from 'tools/acl';
import seo from 'tools/seo';

import LoginModal from 'components/Login/LoginModal';

export default function Login() {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation('login');

  const userProfile = useSelector(state => state.user?.privateProfile);

  useEffect(() => {
    const queryParam = new URLSearchParams(location.search);
    const token = queryParam.get('token');
    if (token) {
      localStorage.setItem('user_token', token);
      history.push(`/home`);
    }
    if (acl.isConnected(userProfile)) {
      history.push(`/home`);
    }
  }, [location, history]);

  return (
    <div className="Login">
      <Helmet>
        {seo.title(t('meta-title'))}
        {seo.description(t('meta-description'))}
      </Helmet>
      <LoginModal onClose={() => history.push(`/`)} noDismiss={false} shouldRedirect />
    </div>
  );
}
